/* */
body {

    background: #F8FAFC;

}
.error-msg {

    color:red;
    font-size: 13px;
    position: relative;
    top:5px;
}

.sort-icon {

    width: 15px;
    height: 10px;
    padding: 2px;
    cursor: pointer;

    display: none;
}

.sorting {

    float:right;
}

.ch-custom-thead {

    border: 1px solid #ccc !important;
}

.ch-custom-thead th {

    border:1px solid #ccc;
    border-collapse: collapse;
}

.ch-custom-td {

    padding-right: 100px;

}

.video {
    cursor: pointer;
  }

  video {
    width: 100%;
    max-height: 100%;
}

.tabs-video-cust {

    width: 800px;
    margin-top: 20px;
}

.acc {
    box-shadow: 0px 4px 10px 0px #00000024;
    border-radius: 0px 0px 12px 12px;
}
button.shadowbox {
    box-shadow: 0px 2px 10px 0px #00000021;
    color: black !important;
}
button.flex.items-center.mt-\[20px\].justify-between.w-\[680px\].mx-auto.shadowbox.p-5.font-semibold.text-left.text-black.rounded-\[12px\].bg-white.bg-gray-100.dark\:bg-gray-800.text-gray-900.dark\:text-white {
    background: white !important;
    border-radius: 12px 12px 0px 0px !important;
    box-shadow: 0px -4px 12px -2px #0000002b !important;
}
.bg-gray-100 .flex img.pr-2.dots {
    display: none;
}

.c-border {

    box-shadow: 0px 4px 10px 0px #00000024;
    border-radius: 0px 0px 12px 12px;
    border: 1px solid #CCC;
}

.card-border {

    border: 1px solid lightgrey;
    padding:1px;
}

input[type=file]::-webkit-file-upload-button {
    visibility: hidden;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .loader-board {

    display: flex;
    margin: auto;
    position: relative;
    left: 50%;
  }

  .loader {

     margin-left:50px;
  }

  .icons-p {

     width: 40px;
     height: 40px;
  }

  .icon-action {

    width: 40px;
    height: 40px;
    cursor: pointer;

  }

  .btn-action {

     padding: 40px;
     cursor: pointer;
  }

  .border-col {

    border: none !important;
  }
 

  .modal-react {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    overflow: auto;
  }